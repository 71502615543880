<template>
    <div class="view-control-position-on-drawing">
        <Loader v-if="isLoading" />
        <div v-else>
            <div class="mt-1 py-2"
                 style="overflow: hidden">
                <div class="drawing--actions-buttons-group-top">
                    <div class="mb-2">

                        <b-button-group size="sm" class="mr-2">
                            <button class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                                    @click="onZoomOut"
                                    title="Zooma ut ritning">
                                <font-awesome-icon icon="search-minus" class="text-info" />
                            </button>
                            <button class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                                    @click="onZoomIn"
                                    title="Zooma in ritning">
                                <font-awesome-icon icon="search-plus" class="text-info" />
                            </button>
                        </b-button-group>

                        <b-button-group size="sm" class="ml-2 mr-2">
                            <b-button variant="light" class="border-gray-light rounded-0" @click="changePositionScale(-0.1)" title="Mindre symboler">-</b-button>
                            <b-button variant="light" class="border-gray-light" disabled>{{pos.symbolSize}}</b-button>
                            <b-button variant="light" class="border-gray-light rounded-0" @click="changePositionScale(0.1)" title="Större symboler">+</b-button>
                        </b-button-group>

                        <b-form-radio-group v-model="pos.symbolSize"
                                            :options="scales"
                                            size="sm"
                                            class="ml-2 border-outline-gray-light"
                                            button-variant="light"
                                            buttons
                                            style="max-height: 31px;">
                        </b-form-radio-group>
                    </div>
                </div>
                <div style="width: 100%;background-color:#fff">
                    <panZoom ref="svgDrawingPanZoom"
                             :options="{
                                        minZoom: 0.1,
                                        maxZoom: 3,
                                        smoothScroll: false,
                                        initialZoom: zoom
                                     }"
                             @panstart="onPanStart"
                             @panend="onPanEnd">
                        <svg ref="box"
                             xmlns="http://www.w3.org/2000/svg"
                             version="1.1"
                             :viewBox="`0 0 ${boxSize.width} ${boxSize.height}`">
                            <!--Drawing-->
                            <image :xlink:href="pos.partDrawingAsBase64"
                                   height="100%"
                                   width="100%" />
                            <g>
                                <!--Line between icon and dot-->
                                <line :x1="pos.coordinateX"
                                      :y1="pos.coordinateY"
                                      :x2="pos.coordinateInfoX"
                                      :y2="pos.coordinateInfoY"
                                      :stroke-width="positionSize.line"
                                      stroke="black" />
                                <!--Grey dot-->
                                <circle :cx="pos.coordinateX"
                                        :cy="pos.coordinateY"
                                        :r="positionSize.radiePoint"
                                        fill="grey" />
                                <!--Circle-->
                                <circle :r="positionSize.radieCircle"
                                        :cx="pos.coordinateInfoX"
                                        :cy="pos.coordinateInfoY"
                                        :fill="posBackgroundColor"
                                        style="cursor:pointer" />
                                <text :x="pos.coordinateInfoX"
                                      :y="pos.coordinateInfoY"
                                      text-anchor="middle"
                                      :alignment-baseline="pos.number >= 100 ? 'middle' : 'middle'"
                                      :font-size="pos.number >= 100 ? positionSize.fontSizeSmall : positionSize.fontSize"
                                      :dy="pos.number >= 100 ? '+.1em' : '0.1em'"
                                      fill="white">
                                    {{ pos.number }}
                                </text>
                            </g>
                        </svg>
                    </panZoom>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { get } from '@/helpers/api';
    import {
        DEFAULT_STATUS_COLOR
    } from '@/services/control-position-status.service';
    import {
        getPositionSizeObject,
        SCALES
    } from '@/helpers/position-symbol.helper';
    import Loader from '@/components/Loader';
    import ControlPositionStatusBall from '@/components/control/ControlPositionStatusBall';

    export default {
        name: 'ViewControlPositionOnDrawing',
        components: {
            Loader,
            ControlPositionStatusBall
        },
        props: {
            positionId: {
                type: Number,
                required: true
            },
            initialZoom: {
                type: Number,
                required: false,
                default: 1
            }
        },
        data() {
            return {
                posBackgroundColor: DEFAULT_STATUS_COLOR,
                zoom: this.initialZoom,
                isLoading: true,
                pos: null,
                isPanning: false,
                boxSize: {
                    width: 500,
                    height: 400
                },
                posScale: 1,
                scales: SCALES
            };
        },
        async mounted() {
            await this.getDrawing();
        },
        computed: {
            positionSize() {
                return getPositionSizeObject(this.pos.symbolSize);
            }
        },
        methods: {
            onZoomIn() {
                const instance = this.$refs.svgDrawingPanZoom.$panZoomInstance;
                const current = instance.getTransform();
                instance.smoothZoom(current.x, current.y, 1.5);
            },
            onZoomOut() {
                const instance = this.$refs.svgDrawingPanZoom.$panZoomInstance;
                const current = instance.getTransform();
                instance.smoothZoom(current.x, current.y, 0.75);
            },
            onPanStart(event) {
                this.isPanning = true;
            },
            onPanEnd(event) {
                this.isPanning = false;
            },
            getStatusColor(pos) {
                return getStatusColor(pos);
            },
            changePositionScale(change) {
                let scale = Math.round((this.pos.symbolSize + change) * 10) / 10
                if (scale <= 0) scale = 0.1;
                if (scale > 3) scale = 3;
                this.setPartSymbolSize(scale);
            },
            setPartSymbolSize(newSize) {
                this.pos.symbolSize = newSize;
            },
            async getDrawing() {
                this.isLoading = true;
                await get('Control', `GetControlPartPositionDrawing/${this.positionId}`)
                    .then(x => {
                        this.pos = x.data
                        this.isLoading = false;
                    })
                    .catch(() => alert('Något gick snett vid försök att hämta ritning.'));
            }
        }
    };
</script>

<style lang="scss">
    .view-control-position-on-drawing .drawing--actions-buttons-group-top {
        z-index: 10;
        position: relative;
        width: 100%;
        text-align: center;
    }
</style>
