<template>
  <div>
    <img v-if="id" 
         style="height: 1rem" 
         :src="getUrl(id)" />
    
    <a href="#"
       @click="openModal">{{ title }}</a>
    <b-modal
      v-if="article"
      v-model="visible"
      scrollable
      hide-footer
      size="xl"
      :title="title"
    >
      <img :src="article.imageAsBase64" />
      <div v-html="article.info" />
    </b-modal>
  </div>
</template>

<script>
import { getDownloadUrlByArticleId } from '@/helpers/file.helper';
import { get } from '@/helpers/api';
import {
    getFirst
} from '@/services/article.service';

export default {
  name: 'ArticleModalViewer',
  props: {
    id: Number,
    title: String,
    isOffline: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      visible: false,
      article: null
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && oldValue !== newValue && newValue > 0)
          this.getArticle();
      }
    }
  },
  methods: {
      async getArticle() {
          if (this.isOffline) {
              this.article = await getFirst(this.id);
          }
          else {
              get('Article', `Get?id=${this.id}`).then((x) => {
                  this.article = x.data;
              });
          }
    },
    getUrl(id) {
      return getDownloadUrlByArticleId(id);
    },
    openModal(){
        this.visible = true;
    }
  }
};
</script>

<style scoped>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}

.v-select.vs--single.vs--searchable {
  min-width: 100%;
  background: white;
}

.v-select .vs__dropdown-toggle {
  min-height: 38px;
}

.vs__selected {
  min-height: 100%;
}
</style>
