<template>
    <div class="d-inline-block">
        <b-modal v-model="visible"
                 hide-footer
                 :title="title"
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header-close>
            <b-form class="d-block px-2"
                    @submit.prevent="onSubmit">
                <b-form-group id="send-lack-mail--form-group--recipients"
                              label="Mottagare (Välj eller skriv egna)"
                              label-for="send-lack-mail--recipients">
                    <v-select ref="send-lack-mail--recipients"
                              id="send-lack-mail--recipients"
                              v-model="recipients"
                              taggable
                              multiple
                              push-tags
                              placement="top"
                              :filterable="true"
                              :options="emails"
                              :create-option="email => ({ fullName: email, email: email })"
                              @click.stop=""
                              v-on:keyup.stop=""
                              placeholder="Till mottagare..."
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="send-lack-mail--recipients-help-block"
                              label="email">
                        <template slot="no-options">
                            Inga resultat
                        </template>
                        <template slot="option" slot-scope="option">
                            {{ option.fullName }} ({{ option.email }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.fullName }} ({{ option.email }})
                        </template>
                    </v-select>
                    <b-form-text id="send-lack-mail--recipients-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Recipients')"
                                :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>

                <b-alert :show="validationByKey('Other').length > 0"
                         variant="danger">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Other')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-alert>

                <div class="d-flex justify-content-between">
                    <b-button variant="warning"
                              @click="closeModal">
                        Avbryt
                    </b-button>

                    <b-button variant="primary"
                              :disabled="isSubmitting"
                              type="submit">
                        <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Skicka
                    </b-button>
                </div>
            </b-form>
        </b-modal>

        <slot name="button" v-bind:openSendModal="openModal"></slot>
    </div>
</template>

<script>
    import { post, get } from '@/helpers/api';
    import { validateEmail } from '@/helpers/email.helper';

    export default {
        name: 'SendLackMail',
        props: {
            title: String,
            lackId: Number,
            show: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            show(newValue) {
                if (newValue)
                    this.openModal();
            }
        },
        data() {
            return {
                isSubmitting: false,
                visible: this.show || false,
                recipients: [],
                validationErrors: [],
                emails: []
            }
        },
        methods: {
            closeModal() {
                this.recipients = '';
                this.$emit('closed');
                this.visible = false;
            },
            async openModal() {
                await get('Control', `GetEmails/${this.lackId}`).then(x => {
                    this.emails = x.data;
                    this.visible = true;
                });
            },
            async onSubmit() {
                this.validationErrors = null;

                if (!this.isEmailsValid())
                    return;

                this.isSubmitting = true;

                await post('Control', `SendLackMail/${this.lackId}`, { id: this.lackId, recipients: this.recipients })
                    .then(() => {
                        this.isSubmitting = false;
                        this.closeModal();
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                        this.isSubmitting = false;
                    });
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            isEmailsValid() {
                let invalidEmailsErrorMessages = [];

                // Check if any.
                if (this.recipients.length === 0) {
                    this.validationErrors = { Recipients: invalidEmailsErrorMessages };
                    invalidEmailsErrorMessages.push(`Du måste ange minst en e-postadress.`);
                    return false;
                }

                // Validate emails.
                this.recipients.forEach((recipient) => {
                    if (!validateEmail(recipient.email.trim()))
                        invalidEmailsErrorMessages.push(`"${recipient.email.trim()}" är inte i korrekt format.`);
                });

                this.validationErrors = { Recipients: invalidEmailsErrorMessages };
                return invalidEmailsErrorMessages.length === 0;
            }
        }
    };
</script>

