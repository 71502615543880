<template>
    <div>
        <b-modal hide-footer
                 v-model="isOpen"
                 size="xl"
                 scrollable 
                 title=""
                 id="view-control-position-drawing-modal">
            <template v-slot:default>
                <ViewControlPositionOnDrawing :positionId="positionId"
                                              :initialZoom="1" />    
            </template>
        </b-modal>

        <div class="px-2">
            <slot name="button" v-bind:openViewControlPositionOnDrawingModal="openModal"></slot>
        </div>
    </div>
</template>

<script>
    import ViewControlPositionOnDrawing from '@/components/control/ViewControlPositionOnDrawing';

    export default {
        name: 'ViewControlPositionOnDrawingModal',
        components: {
            ViewControlPositionOnDrawing
        },
        props: {
            positionId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                isOpen: false
            }
        },
        methods: {
            openModal() {
                this.isOpen = true;
            }
        }
    }
</script>

<style>
    @media (min-width: 576px) {
        #view-control-position-drawing-modal .modal-dialog {
            max-width: 92vw;
            margin: 1.75rem auto;
        }
    }

    #view-control-position-drawing-modal .modal-body {
        padding: 0;
    }

    #view-control-position-drawing-modal .drawing--actions-buttons-group-top > div {
        margin: 0;
        padding: 0;
    }
</style>